<template>
    <span class="sam-input-error" v-if="error">
        {{errorMessage}}
    </span>
</template>

<script>

export default {
  name: 'SamErrorMessage',
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
  }
}
</script>
<style lang="scss" scoped>

.sam-input-error {
  position: absolute;
  bottom: -15px;
  left:0;
  right: 0;
  font-size: $smallFontSize;
  line-height: $smallLineHeight;
  color: $alertRed;
  font-family: $boldFontStyle;
}

@media (max-width: 1010px) and (min-width: 720px)  {
  .sam-input-error {
    bottom: -1.75rem;
  }
}
</style>