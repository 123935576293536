<template>
  <div class="sam-tooltip d-flex align-items-center">
    <p class="sam-tooltip-title m-0">
      <slot/>
    </p>
    <span class="sam-tooltip-icon ml-1" :id="tooltipId"></span>
     <b-tooltip :target="tooltipId" triggers="hover">
         {{text}}
    </b-tooltip>
  </div>
</template>
<script>

export default {
  name: 'SamTooltip',
  data() {
    return {
      hover: false,
    };
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    tooltipId: {
      type: String,
      default: ''
    }
  }
}
</script>

