<template>
  <label :class="['sam-label', marginBottomClass, 'mt-0']" v-if="label">{{ label }}</label>
</template>

<script>
export default {
  name: 'SamLabel',
  props: {
    label: {
      type: String,
      default: ''
    },
    marginBottomClass: {
      type: String,
      default: 'mb-1'
    }
  }
}
</script>

<style lang="scss" scoped>
.sam-label {
  font-family: $boldFontStyle;
}
</style>
