<template>
  <div class="sam-form-control mb-4">
    <SamTooltip v-if="tooltipId !== ''" :text="tooltipText" :tooltipId="tooltipId">
      <div class="label-container">
        <SamLabel
            :label="label"
            :marginBottomClass="labelMarginBottomClass || undefined"
            noSpace
        />
        <span class="additional-text" v-if="additionalText" @click="handleClick" style="text-decoration: underline; cursor: pointer;">
          {{ additionalText }}
        </span>
      </div>
    </SamTooltip>
    <div v-else class="label-container">
      <SamLabel
          :label="label"
          :marginBottomClass="labelMarginBottomClass || undefined"
          noSpace
      />
      <span class="additional-text" v-if="additionalText" @click="handleClick" style="text-decoration: underline; cursor: pointer;">
        {{ additionalText }}
      </span>
    </div>
    <input
        class="form-control sam-input sam-text-field"
        :class="{'error': error, 'custom-date-picker': replaceWithCustomIcon && type === 'date'}"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        :placeholder="placeholder"
        :required="required"
        :disabled="disabled"
        :pattern="pattern"
        :type="type"
        :min="minDate"
        :max="maxDate"
        :readonly="readOnly"
    >
    <SamErrorMessage :error="error" :errorMessage="errorMessage" />
  </div>
</template>

<script>
import SamLabel from './SamLabel.vue'
import SamErrorMessage from './SamErrorMessage.vue'
import SamTooltip from './SamTooltip.vue'

export default {
  name: 'SamTextField',
  components: {
    SamLabel,
    SamErrorMessage,
    SamTooltip
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    pattern: {},
    value: {},
    tooltipId: {
      type: String,
      default: ''
    },
    tooltipText: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    minDate: {
      type: String,
      default: ''
    },
    maxDate: {
      type: String,
      default: ''
    },
    customIcon: {
      type: String,
      default: ''
    },
    replaceWithCustomIcon: {
      type: Boolean,
      default: false
    },
    additionalText: {
      type: String,
      default: ''
    },
    onClick: {
      type: Function,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    labelMarginBottomClass: {
      type: String,
      default: null
    }
  },
  methods: {
    handleClick(event) {
      if (this.onClick) {
        this.onClick(event);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sam-form-control {
  position: relative;
}

.label-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.additional-text {
  font-family: $boldFontStyle;
  font-size: 16px;
  line-height: 24px;
  //font-weight: 400;
  color: #A7A7A7;
}

.sam-input.sam-text-field {
  height: 3rem;
  box-shadow: none;
  border-radius: $mainRadius;
  border: 1px solid $lightGrey;
  color: $mainColor;
}

.sam-text-field.error {
  border-color: $alertRed;
}

.sam-text-field:focus,
.sam-text-field:active {
  box-shadow: none;
  border: 1px solid #EAEAEA;
}

.sam-text-field:disabled {
  background-color: $white;
  opacity: 0.5;
}

input[type="date"].custom-date-picker::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url('../assets/images/calendar-date.svg') no-repeat;
  width: 25px;
  height: 25px;
  border-width: thin;
  background-position: center;
}

input[type="date"].custom-date-picker.custom-icon::-webkit-calendar-picker-indicator {
  background: var(--custom-icon-url) no-repeat;
  background-position: center;
}
</style>
